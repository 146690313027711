import { render, staticRenderFns } from "./RouteV2.vue?vue&type=template&id=6ecc7444&scoped=true&"
import script from "./RouteV2.vue?vue&type=script&lang=js&"
export * from "./RouteV2.vue?vue&type=script&lang=js&"
import style0 from "./RouteV2.vue?vue&type=style&index=0&id=6ecc7444&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ecc7444",
  null
  
)

export default component.exports