<template>
  <div class="page">
    <div class="roll_back_line flex justify-between">
      <div @click="goBack" class="inline-block flex w-1/3 justify-start items-center go_back">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14 18L8 12L14 6" stroke="#6c757d" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <div class="h-full label_roll_back">
          Back
        </div>
      </div>
    </div>
    <div class="title_line flex items-center justify-between w-full px-16">
      <h1>Route Details</h1>
      <div class="route-action flex gap-10p">
        <div @click="printPage()" class="text-grey-dark hover:text-primary" title="Print Preview">
          <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" size="20" height="20" width="20"><path d="M19 8H5c-1.66 0-3 1.34-3 3v6h4v4h12v-4h4v-6c0-1.66-1.34-3-3-3zm-3 11H8v-5h8v5zm3-7c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm-1-9H6v4h12V3z"></path></svg>
        </div>
      </div>
    </div>

    <div class="body" v-if="error_load">
      <div class="w-full error">
        Oooops! Something went wrong!
      </div>
    </div>

    <div class="body" v-if="!error_load && loaded_info">
      <div class="route_details px-16 py-5">
        <div class="text-left bordered rounded p-2" >
          <div class="title rounded bg-grey-lighter py-5 px-5">
            <h2 >Route details</h2>
          </div>
          <div class="info">
            <div class="info_group flex w-full py-4">
              <div class="name w-1/3">
                Company
              </div>
              <div class="value w-2/3 px-4">
                {{user_data.company_name}}
              </div>
            </div>
            <div class="info_group flex py-4">
              <div class="name w-1/3">
                Route ID
              </div>
              <div class="value w-2/3 px-4">
                {{$route.params.id}}
              </div>
            </div>
            <div class="info_group flex py-4">
              <div class="name w-1/3">
                Route name
              </div>
              <div class="value w-2/3 px-4">
                {{route_data.name}}
              </div>
            </div>
            <div class="info_group flex py-4">
              <div class="name w-1/3">
                Origin
              </div>
              <div class="value w-2/3 px-4">
                {{route_data.route_info.locations[0].geo.postcode}}, {{route_data.route_info.locations[0].geo.region}}
              </div>
            </div>
            <div class="info_group flex py-4" v-for="(location, index) in route_data.direction_data.routes[0].sections" :key="index">
              <div class="name w-1/3">
                {{ index +1 }} Destination
              </div>
              <div class="value w-2/3 px-4">
                {{ location.arrival.place.postalCode }}, {{ location.arrival.place.address }}
              </div>
            </div>
            <div class="info_group flex py-4">
              <div class="name w-1/3">
                Submission date
              </div>
              <div class="value w-2/3 px-4">
                {{route_data.created_at | moment('DD-MM-YYYY')}}
              </div>
            </div>
            <div class="info_group flex py-4">
              <div class="name w-1/3">
                Start time
              </div>
              <div class="value w-2/3 px-4">
                {{getDateTime(route_data.direction_data.routes[0].sections[0].departure.time)}}
<!--                {{route_data.direction_data.routes[0].sections[0].departure.time | moment('DD-MM-YYYY, HH:mm')}}-->
              </div>
            </div>
            <div class="info_group flex py-4">
              <div class="name w-1/3">
                End time
              </div>
              <div class="value w-2/3 px-4">
                {{getDateTime(route_data.direction_data.routes[0].sections[route_data.direction_data.routes[0].sections.length-1].arrival.time)}}
<!--                {{// route_data.direction_data.routes[0].sections[route_data.direction_data.routes[0].sections.length-1].arrival.time | moment('DD-MM-YYYY, HH:mm')}}-->
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="vehicles_details px-16 py-5" v-if="route_data.vehicle_info && route_data.vehicle_info.length">
        <div class="text-left bordered rounded p-2">
          <div class="title rounded bg-grey-lighter py-5 px-5 w-full text-center flex items-center justify-between gap-10p">
            <div class="head_item">Vehicle name</div>
            <div class="head_item">Reg. number</div>
            <div class="head_item">Make</div>
            <div class="head_item">Type</div>
            <div class="head_item">Height</div>
            <div class="head_item">Width</div>
            <div class="head_item">Length</div>
            <div class="head_item">Weight</div>
          </div>
          <div class="vehicles">
            <div class="items py-5 px-5 text-center flex items-center justify-between gap-10p" v-for="(veh, i) in route_data.vehicle_info" :key="i">
              <span class="item">{{veh.name || '-'}}</span>
              <span class="item">{{veh.registration_number || '-'}}</span>
              <span class="item">{{veh.vehicle_make || '-'}}</span>
              <span class="item text-100px">
                  {{ veh.vehicle_type ? parseTypeByKeys(veh.vehicle_type) : '-' }}
              </span>
              <span class="item">{{veh.height || '-'}} m</span>
              <span class="item">{{veh.width || '-'}} m</span>
              <span class="item">{{veh.length || '-'}} m</span>
              <span class="item">{{veh.weight || '-'}} t</span>
            </div>
          </div>
        </div>
      </div>

      <div class="trailers_details px-16 py-5" v-if="route_data.trailer_info && route_data.trailer_info.length">
        <div class="text-left bordered rounded p-2">
          <div class="title rounded bg-grey-lighter py-5 px-5 w-full text-center flex items-center justify-between gap-10p">
            <div class="head_item">Trailer name</div>
            <div class="head_item">Trailer id</div>
            <div class="head_item">QR code</div>
            <div class="head_item">Height</div>
            <div class="head_item">Width</div>
            <div class="head_item">Length</div>
          </div>
          <div class="trailers">
            <div class="items py-5 px-5 flex items-center justify-between gap-10p" v-for="(veh, i) in route_data.trailer_info" :key="i">
              <span class="item">{{veh.name || '-'}}</span>
              <span class="item">{{veh.trailer_id || '-'}}</span>
              <span class="item">{{veh.qr_code || '-'}}</span>
              <span class="item">{{veh.height || '-'}} m</span>
              <span class="item">{{veh.width || '-'}} m</span>
              <span class="item">{{veh.length || '-'}} m</span>
            </div>
          </div>
        </div>
      </div>

      <div class='reach_text px-16' v-if='react_text'>
        <div class=' py-5' v-html='react_text'></div>
      </div>
      <div class="directions px-16 py-6 w-full flex items-center justify-between gap-15">
        <div class="w-5/12 direction">
          <div class="inner way" v-if="route_data.direction_data.routes[0].sections">
            <div class="flex-none p-4 border-b">
              <div class="flex justify-between items-center">
                <strong class="text-xl font-medium">
                  Directions
                </strong>
              </div>
              <div class="justify-between font-medium">
                <div class="block text-lg text-orange-dark">
                  {{ secondsToDhms() }}
                  <span class="text-lg text-grey-dark">{{ parsedDistance.m }} miles ({{ parsedDistance.k }} km)</span>
                </div>
                <div class="text-xs pt-1 text-grey-dark">
<!--                  Vehicle Dimensions:-->
<!--                  H:{{ getVehiclesDimensions.height.val }}m-->
<!--                  W:{{ getVehiclesDimensions.width.val }}m-->
<!--                  L:{{ getVehiclesDimensions.length.val }}m-->
<!--                  Wt:{{ getVehiclesDimensions.weight.val }}t-->
                </div>
              </div>
            </div>
            <RouteInstructions
                v-if="route_data.direction_data.routes[0].sections"
                :sections="route_data.direction_data.routes[0].sections"
            />
          </div>
        </div>
        <div class="w-7/12 map">
          <HereMap
              v-if='route_data.direction_data'
              :routeData='route_data.direction_data'
              :is_plan="false"
              :map_width="'700px'"
              :map_height="'580px'"
              :zoom_default="6"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import moment from "moment";
import {createIconWithText, parseTypeByKeys} from "@/services/helpers";
import RouteInstructions from "@/components/plan-elements/RouteInstructions.vue";
import HereMap from "@/components/HereMap.vue";
import {baseErrHandler, getUserRoutesText} from "@/services/api";
export default {
  name: "Route",
  components: {
    HereMap,
    RouteInstructions,
  },
  data() {
    return {
      route_data: [],
      react_text: '',
      user_data: JSON.parse(localStorage.getItem('user')),
      colors: {
        0: 'blue',
        1: 'red',
        2: 'green',
        3: 'yellow',
        4: 'blue',
        5: 'red',
        6: 'green',
        7: 'yellow',
        8: 'blue',
        9: 'red'
      },
      timeDuration: 0,
      distance: 0,
      parsedDistance: {
        k: null,
        m: null
      },
      waypoints: [],
      partDistance: [],
      zoom: 6,
      center: { lat: 53.495949, lng: -0.131529 },
      url: "https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZGF3c29uc2NvdHQiLCJhIjoiY2p4Ymp5YzhlMWU2eDNvbGdpM2o5endvYyJ9.dOMZ433p1ilQN22-njY91A",
      attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      traffic_main: null,
      add_vehicles: null,
      low_emission: null,
      ulez: null,
      london_control: null,
      error_load: false,
      loaded_info: false,
    }
  },
  computed: {
    ...mapGetters([
      'getMultiWaypointsText',
      'getColors',
      'getMarkers',
      'getMultiWaypoints'
    ]),
  },
  methods: {
    parseTypeByKeys,
    createIconWithText,
    ...mapMutations([
      'setMultiWaypointsText',
      'setMarkers',
      'setMultiWaypoints',
      'setLoading'
    ]),
    async printPage() {
      if (this.route_data.route_version === 'v2') {
        return await this.$router.push({name: "RoutePrintV2", params: {id: this.$route.params.id}});
      }
      await this.$router.push({path: '/routes/' + this.$route.params.id + '/print'})
    },
    goBack() {
      this.$router.go(-1);
    },
    getDateTime(dtime) {
      const formattedDate = dtime.replace(/T(\d{2}:\d{2}):.*$/, ' $1');
      return moment(formattedDate).format('DD-MM-YYYY, HH:mm');
    },
    estimatedEndTime(dtime) {
      return moment(dtime).add(this.timeDuration, 'seconds').format('DD-MM-YYYY, HH:mm');
    },
    async getRoute() {
      this.setLoading(true);
      try {
        const route = await this.$http.getAuth(`${this.$http.apiUrl()}/routes/${this.$route.params.id}`) || [];
        this.route_data = route.data;
        await this.parseDistance();
      } catch (e) {
        console.log(e);
        this.error_load = true;
        this.setLoading(false);
      }
      this.error_load = false;
      this.loaded_info = true;
      this.setLoading(false);
    },
    secondsToDhms() {
      const seconds = this.route_data.direction_data.routes[0].sections.reduce((acc, section) => {
        return acc + section.travelSummary.duration;
      }, 0);
      var d = Math.floor(seconds / (3600*24));
      var h = Math.floor(seconds % (3600*24) / 3600);
      var m = Math.floor(seconds % 3600 / 60);

      var dDisplay = d > 0 ? d + (" d, ") : "";
      var hDisplay = h > 0 ? h + (" h, ") : "";
      var mDisplay = m > 0 ? m + ("min") : "";
      return dDisplay + hDisplay + mDisplay;
    },
    async parseDistance() {

      const travelLength = this.route_data.direction_data.routes[0].sections.reduce((acc, section) => {
        return acc + section.travelSummary.length;
      }, 0);
      this.parsedDistance.k = Math.round(travelLength / 1000);
      this.parsedDistance.m = Math.round((travelLength / 1000) / 1.609344);
    },
    distanceToString(meters) {
      if(meters)
        return `${Math.round(meters / 1000)} Km (${Math.round((meters / 1000) / 1.609344)} miles)`;
      else return ''
    },

    async getRoutesText() {
      await baseErrHandler(async () => {
        const user = await getUserRoutesText();

        this.react_text = user?.data?.user?.routes_info;
      });
    },
  },
  async mounted() {
    await this.getRoute();
    await this.getRoutesText();
  },
}
</script>

<style lang="scss" scoped>
.direction {
  .inner {
    max-height: 550px;
    height: 550px;
    overflow-y: scroll;
  }
}
.map {
  max-width: 700px;
  max-height: 550px;
  height: 550px;
}
</style>
